.activation-email-page {
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10%;
    padding-right: 10%;
    background-color: $csu-color-accent-light-blue;

    .activation-email-container {
        background-color: $csu-color-white;
        width: 100%;
        border-radius: 50px;
        padding: 40px;
        margin-bottom: $csu-margin-page-mb;
        padding-bottom: $csu-margin-page-mb;
    }

    .activation-email-inner-container {
        padding: 0;
    }

    p {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.3px;
        margin-bottom: 0;
    }

    h1 {
        padding-bottom: 20px;
        text-transform: uppercase;
    }

    .activation-email-button {
        width: 145px;
        border: transparent;
    }

    .activation-email-row {
        display: flex;
        padding-bottom: 15px;
        padding-left: 20px;
    }

    .activation-email-col {
        padding-top: 5px;
    }

    .activation-email-label {
        padding-top: 10px;
    }

    .activation-email-input {
        &:focus {
            outline: none;
            box-shadow: none;
        }

        border-radius: 20px;
        border: transparent;
        padding-left: 10px;
        background-color: $csu-color-accent-light-blue;
        height: 40px;
        color: $csu-color-grey;
    }
}
