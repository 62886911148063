.g-basic-popup {
  &.g-basic-popup-big {
    .modal-dialog {
      max-width: 700px;
    }
  }

  .modal-content {
    border-radius: 10px;
    margin: 0px 15px;
    padding: 40px 20px;
  }

  .modal-body {
    padding: 0;
  }

  .modal-header {
    align-items: flex-end;
    display: flex;
    margin-bottom: 30px;
    min-height: 40px;
    padding: 0;
    position: relative;
    width: 100%;

    .modal-header__close {
      cursor: pointer;
      left: -10px;

      position: absolute;
      top: -30px;
      width: 16px;
    }

    .modal-header__headers {
      width: 100%;
    }

    h1 {
      margin-top: 35px;
      text-align: center;
      width: 100%;
    }

    h2 {
      text-align: center;
    }
  }

  .modal-footer {
    padding: 0;
  }
}
