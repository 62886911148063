$chatinterface-input-attachment-height: 200px;

.g-chat-interface {
  .g-basic-modal-body.message-input-expanded {
    padding-bottom: $chatinterface-input-attachment-height + $csu-margin-page-mb;
  }
  .modal-footer {
    background-color: transparent;
    background-color: $csu-color-accent-blue;
    height: auto;
    max-height: 220px;
    padding: 5px;
  }
  .g-chat-footer {
    align-items: center;
    background-color: transparent;
    display: flex;
    margin-left: 10px;
    margin-right: 20px;
    width: 100%;

    .g-chat-message-input-container {
      background-color: $csu-color-white;
      border: 0;
      border-radius: 20px;
      box-shadow: $csu-box-shadow-black;
      margin-right: 36px;
      position: relative;
      width: 100%;

      &.has-attachment {
        align-items: flex-end;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        height: $chatinterface-input-attachment-height;

        .selected-image-container {
          height: 80%;
          overflow: hidden;
          position: relative;
          width: 99.5%;

          img {
            max-height: 100%;
            max-width: 100%;
            padding: 10px;
            vertical-align: middle;
          }

          .delete-image {
            @include close-button-blue-cross-white-circle;
            height: 30px;
            left: 15px;
            position: absolute;
            top: 15px;
            width: 30px;
          }
        }
      }
    }

    .g-message-textarea {
      background-color: transparent;
      border: 0;
      max-height: 168px;
      padding: 12px 41px 12px 20px;
      resize: none;
    }

    .g-send-message-button {
      bottom: 8px;
      cursor: pointer;
      position: absolute;
      right: 60px;
    }

    .g-attachment-button {
      bottom: 8px;
      height: 40px;
      position: absolute;
      right: 12px;
    }
  }
}
