.account-registration-request-management-page {
    .account-registration-request-management-table-top {
        display: flex;
        justify-content: end;
        border-radius: 30px 30px 0 0;
        background-color: $csu-color-white;
        padding: .5rem;
    }

    .account-registration-request-management-table {
        .account-registration-request-actions {
            img {
                height: 20px;
                cursor: pointer;

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                &.disabled {
                    cursor: default;
                    opacity: 0.2;
                }
            }

        }
    }

    .account-registration-request-management-table-row-expander-open,
    .account-registration-request-management-table-row-expander-closed {
        height: 15px;
        width: 15px;
        background-image: url("../../images/dropdown.svg");
        background-position: 0;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .account-registration-request-management-table-row-expander-open {
        transform: rotate(180deg);
    }
}

.account-registration-row {
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.account-registration-debtor-input {
    &:focus {
        outline: none;
        box-shadow: none;
    }

    border-radius: 20px;
    border: transparent;
    padding-left: 10px;
    background-color: $csu-color-accent-light-blue;
    height: 40px;
    width: 90%;
    color: $csu-color-grey;
}

.account-registration-check-buttons {
    width: 100%;
    text-align: center;
    padding-top: 20px;

    .g-btn {
        width: calc(50% - 5px);

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }
}

.account-registration-fetched-info-row {
    margin-bottom: 1rem;
    align-items: center;
}

.account-registration-fetched-info-right-col {
    padding: 0.75rem;
    border-radius: 20px;
    background-color: $csu-color-accent-light-blue;
    min-height: 36px;
    overflow-x: hidden;
}

.account-registration-table-col {
    margin-bottom: 1rem;
}

.account-registration-fetched-info-row .account-registration-add-user-button {
    outline: none;
    border: none;
    width: auto;
}

.account-registration-table-row-button {
    align-items: center;
}

.account-registration-table-col-button {
    display: flex;
    margin: 0.5rem;
}
