// CSU ZiZO colors
// Base ZiZO brand colors
$csu-color-blue: #1946ff;
$csu-color-dark-blue: #0c34d4;
$csu-color-turquoise: #35e9fc;
$csu-color-pink: #f72aee;
$csu-color-yellow: #fab942;
$csu-color-grey: #848484;
$csu-color-white: #ffffff;
$csu-color-black: #000000;
$csu-color-red: #fa4242;

// Accents
$csu-color-accent-blue: #f8f9ff;
$csu-color-accent-grey-light: #e2e2e2;
$csu-color-accent-grey-medium: #cecece;
$csu-color-accent-red: #c60000;
$csu-color-accent-light-blue: #f2f8ff;
$csu-color-accent-green: #74d799;

// Shadow
$csu-shadow-black: 0 0 9px 3px rgba(0, 0, 0, 0.1);

// Gradient
$csu-gradient-roze: linear-gradient(223.75deg, #f72aee 0%, #fab942 66.21%);
$csu-gradient-roze-hover: linear-gradient(216.38deg, #f72aee -4.29%, #fab942 117.92%);

// Font family
$csu-font-default: "Crossten Book", Verdana, sans-serif;
$csu-font-bold: "Crossten Bold", Verdana, sans-serif;
$csu-font-black: "Crossten Black", Verdana, sans-serif;
$csu-font-extra-bold: "Crossten Extra Bold", Verdana, sans-serif;
$csu-font-light: "Crossten Light", Verdana, sans-serif;
$csu-font-medium: "Crossten Medium", Verdana, sans-serif;
$csu-font-semi-bold: "Crossten Semi Bold", Verdana, sans-serif;

// Bootstrap variable
$input-font-family: $csu-font-default;

// Syncfusion variable
$font-family-base: $csu-font-default;

// Box shadows and borders
$csu-box-shadow-black: 0 0 4px 4px rgba(0, 0, 0, 0.05);
$csu-border-color-light-gray: #ddd;

// Font sizes
$csu-fontsize-x-small: 0.67rem; // 8px als $font-size-base = 12px
$csu-fontsize-small: 0.83rem; // 10px als $font-size-base = 12px
$csu-fontsize-normal: 1rem; // = 1 * 12px (root font-size)
$csu-fontsize-large: 1.168rem; // 14px als $font-size-base = 12px
$csu-fontsize-larger: 1.5rem; // 18px als $font-size-base = 12px
$csu-fontsize-x-large: 1.75rem; // 21px als $font-size-base = 12px
$csu-fontsize-xx-large: 2.33rem; // 28px als $font-size-base = 12px

// margins
$csu-margin-page-mt: 30px;
$csu-margin-page-ml: 40px;
$csu-margin-page-mr: 40px;
$csu-margin-page-mb: 20px;

// Element sizes
$g-navbar-height: 80px;

//    bootstrap variables
$font-size-sm: $csu-fontsize-small;
$font-size-base: $csu-fontsize-normal;
$font-size-lg: $csu-fontsize-large;
$font-size-xl: $csu-fontsize-x-large;

// button sizes & radius
$csu-button-size-small-height: 40px;
$csu-button-size-small-width: 60px;
$csu-button-size-small-square: 50px;
$csu-button-size-normal-width: 165px;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// Form sizes
$csu-size-inputfield-x-large-height: 40px;
$csu-size-inputfield-x-large-width: 760px;
$csu-size-inputfield-large-height: 40px;
$csu-size-inputfield-large-width: 400px;
$csu-size-inputfield-normal-height: 40px;
$csu-size-inputfield-normal-width: 300px;
$csu-size-inputfield-small-width: 200px;

$input-height: 40px;

// variable extensions
%text-default {
  font-family: $font-family-base;
  font-weight: normal;
  font-size: $font-size-base;
}

//    bootstrap variables
$green: $csu-color-accent-green;
$black: $csu-color-black;
$white: $csu-color-white;
$gray-100: $csu-color-accent-grey-light;
$gray-300: $csu-color-accent-grey-light;
$gray-600: $csu-color-accent-grey-medium;
$red: $csu-color-accent-red;
$yellow: $csu-color-yellow;
$blue: $csu-color-blue;
$gray-700: $csu-color-grey;
