:root {
  font-size: 12px; // important for use of rem unit (https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units)
}

// Ensure 100% in app is really 100% of page
:root,
#root,
#root>div,
.app {
  height: 100%;
}

body {
  padding: 0;
  font-family: $csu-font-default;
  background-color: $csu-color-accent-light-blue;
  overflow: auto;
}

.clickable {
  cursor: pointer;  
}

.hidden {
  display: none !important;
}

@mixin fit-image-to-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

@mixin close-button-blue-cross-white-circle {
  width: 30px;
  height: 30px;
  background-color: $csu-color-white;
  border-radius: 50%;
  background-image: url("../../images/close-blue-gradient.svg");
  background-size: 14px;
  background-position: 50%;
  background-repeat: no-repeat;
  box-shadow: $csu-box-shadow-black;
}

@font-face {
  font-family: "Crossten Book";
  src: url("../fonts/HorizonType-CrosstenBook.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Black";
  src: url("../fonts/HorizonType-CrosstenBlack.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Bold";
  src: url("../fonts/HorizonType-CrosstenBold.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Extra Bold";
  src: url("../fonts/HorizonType-CrosstenExtrabold.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Light";
  src: url("../fonts/HorizonType-CrosstenLight.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Medium";
  src: url("../fonts/HorizonType-CrosstenMedium.otf") format("truetype");
}

@font-face {
  font-family: "Crossten Semi Bold";
  src: url("../fonts/HorizonType-CrosstenSemibold.otf") format("truetype");
}
