.workprogram-content-wrapper {
    display: grid;
    gap: 1rem;
    align-items: flex-start;
    margin-block: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.workprogram-roomtype-card {
    background: $csu-color-white;
    padding: 1.5rem;
    border-radius: 1rem;
    box-shadow: $csu-shadow-black;
}

.workprogram-roomtype-card-title {
    font-size: $csu-fontsize-x-large;
    color: $csu-color-blue;
    text-transform: uppercase;
    font-family: $csu-font-bold;
}

.workprogram-roomtype-card-pill {
    background: $csu-color-blue;
    color: $csu-color-white;
    padding-inline: 1.5rem;
    border-radius: 1rem;
    font-family: $csu-font-default;
}

.workprogram-roomtype-card-trash-img {
    cursor: pointer;
}

.workprogram-add-action {
    cursor: pointer;
}

.workprogram-modal-content label {
    margin: 0;
}

.workprogram-modal-content > input {
    width: 30%;
    text-align: center;
}

.workprogram-modal-content {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}