.flex-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.flex-group p {
    margin: 0;
}

.flex-group > .spinner-border {
    display: flex;
    align-self: flex-end;
}

.workprogram-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    height: 100px;
    width: 300px;
    font-size: 2rem;
    background-color: $csu-color-white;
    outline: 0px solid $csu-color-blue;
    border-radius: .5rem;
    box-shadow: $csu-shadow-black;
    transition: all 100ms ease;
}

.project-id-input {
    width: 200px;
}

.workprogram-button:is(:hover, :focus, .active) {
    background: $csu-color-blue;
    color: $csu-color-white;
}

.work-program-button-title {
    font-size: $csu-fontsize-larger;
}