.g-chat-interface-message {
  background-color: $csu-color-white;
  border-radius: 8px 8px 8px 0px;
  margin-bottom: 20px;
  margin-right: 20%;
  padding: 20px;

  .g-chat-interface-message-content {
    font-size: 14px;
    margin-top: 5px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .g-chat-interface-message-attachment {
    height: 180px;
    width: 180px;

    img {
      width: 100%;
    }
  }

  .g-chat-interface-message-title {
    color: $csu-color-blue;
    font-family: $csu-font-black;
    font-size: 14px;
    font-weight: 900;
    line-height: 13px;
    margin-bottom: 5px;
  }

  .g-chat-interface-message-timestamp {
    font-size: 10px;
    font-weight: 300;
    margin-top: 5px;
  }

  &.g-my-message {
    background-color: $csu-color-blue;
    border-radius: 8px 8px 0 8px;
    color: $csu-color-white;
    margin-left: 20%;
    margin-right: 0;

    .g-chat-interface-message-title {
      color: $csu-color-white;
    }
  }
}
