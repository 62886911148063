.g-nav-link {
  color: $csu-color-black;
  font-family: $csu-font-default;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  display: grid;

  img {
    width: 30px;
  }
}

.navbar-nav .g-nav-link.g-nav-link-selected,
.navbar-nav .g-nav-link.g-nav-link-selected:hover,
.navbar-nav .g-nav-link.g-nav-link-selected:focus {
  color: $csu-color-blue ;
  font-family: $csu-font-bold;
}

.g-nav-icon-selected {
  height: 35px;
  width: 35px;
  margin: auto;
  border-radius: 7px;
}

.g-nav-icon {
  height: 35px;
  width: 35px;
  margin: auto;
  border-radius: 7px;
}
