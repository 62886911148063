.g-system-notification:not(.hidden) {
    width: 100%;
    height: 60px;
    display: table;
    z-index: 999;
    
    &.low {
        background-color: #ECEFFB;
        color: $csu-color-black;
    }

    &.medium {
        background-color: #FFF1CA;
        color: $csu-color-black;
    }

    &.high {
        background-color: #FDE9EB;
        color: $csu-color-black;
    }

    .message {
        text-align: center;
        height: 100%;
        cursor: pointer;
        display: table-cell;
        vertical-align: middle;
    }
}