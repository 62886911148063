.offer-request-management-page {
    .offer-request-management-filters {
        background-color: $csu-color-white;
        border-radius: 20px  20px 0 0;
        width: 100%;
        display: flex;
        padding: 20px;

        select {
            max-width: 25%;
            border-radius: 25px;
            background-color: $csu-color-accent-light-blue;
            border: none;

            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }

    .g-react-table.offer-request-management-table {
        .offer-request-actions {
            img {
                height: 16px;
                cursor: pointer;
                &:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }

        input {
            background-color: $csu-color-accent-light-blue;
            color: $csu-color-grey;
            border: none;
            border-radius: 25px;
            padding: 10px 20px;

            &:read-only {
                background-color: $csu-color-accent-grey-light;
            }
        }

        .g-btn {
            width: 100%;
        }
    }

    .offer-request-management-table-row-expander-open,
    .offer-request-management-table-row-expander-closed {
        height: 15px;
        width: 15px;
        background-image: url("../../images/dropdown.svg");
        background-position: 0;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .offer-request-management-table-row-expander-open {
        transform: rotate(180deg);
    }
}
