.active-regions-overview {
    background-color: $csu-color-accent-light-blue;

    h1 {
        margin-bottom: 30px;
    }

    .active-regions-overview-container {
        padding: 0;

        .active-regions-overview-table {
            border-collapse: collapse;
            width: 100%;
            table-layout: fixed;
            max-width: 450px;

            td,
            th {
                border: 1px solid $csu-border-color-light-gray;
                padding: 8px;
            }

            tr:nth-child(even) {
                background-color: $csu-color-white;
            }

            tr:hover {
                background-color: $csu-border-color-light-gray;
            }

            th {
                padding-top: 12px;
                padding-bottom: 12px;
                background-color: $csu-color-blue;
                color: $csu-color-white;
            }
        }

        .active-regions-align-right {
            text-align: right;
            margin-right: 1em;
        }

        .active-regions-align-center {
            text-align: center;
        }
        
        .delete-button {
            width: 25px;
        }
    }

    .active-regions-add-button {
        margin-left: 10px;
        width: 30px;
    }
}

.active-region-confirmation-prompt-buttons {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    .g-btn {
        width: calc(50% - 5px);
        &:first-child {
            margin-right: 5px;
        }
        &:last-child {
            margin-left: 5px;
        }
    }
}

.active-region-new-region-label {
    padding-top: 10px;
    width: 50%;
}

.active-region-new-region-input {
    &:focus {
        outline: none;
        box-shadow: none;
    }

    border-radius: 20px;
    border: transparent;
    padding-left: 10px;
    background-color: $csu-color-accent-light-blue;
    height: 40px;
    width: 90%;
    color: $csu-color-grey;
}

.active-region-new-region-row {
    display: flex;
    padding-bottom: 15px;
    padding-left: 10px;
}

.active-regions-overview-loading {
    text-align: center;
}
