table.g-react-table {
  background-color: $csu-color-white;
  border: none;
  border-radius: 20px;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;

  thead {
    padding: 10px;

    th {
      background-color: $csu-color-white;
      border-bottom: 1.5px solid $csu-color-accent-light-blue;
      border-top: 1.5px solid $csu-color-accent-light-blue;
      font-family: $csu-font-bold;
      font-size: $csu-fontsize-normal;
      padding: 10px 20px;
    }
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  td {
    border-bottom: 1.5px solid $csu-color-accent-light-blue;
    border-right: 1.5px solid $csu-color-accent-light-blue;
    font-family: $csu-font-default;
    margin: 0;
    padding: 10px 20px;

    &:last-child {
      border-right: 0;
    }

    & div {
      font-family: $csu-font-light;
    }

    & b {
      font-family: $csu-font-bold;
    }
  }

  .g-react-table-sort-icon {
    margin-right: 5px;
    width: 15px;
  }

  // Actions

  .g-react-table__actions {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: space-around;
  }

  .g-react-table__action {
    cursor: pointer;
    height: 20px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &.disabled {
      cursor: default;
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .g-react-table__row-expander-open,
  .g-react-table__row-expander-closed {
    background-image: url("../../../images/dropdown.svg");
    background-position: 0;
    background-repeat: no-repeat;
    background-size: contain;
    height: 15px;
    width: 15px;
  }

  .g-react-table__row-expander-open {
    transform: rotate(180deg);
  }
  // -
}

.pagination-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  .form-control {
    border: none;
    height: 30px;
    width: 60px;
  }

  .form-control:hover {
    cursor: pointer;
  }

  .pagination-buttons {
    align-items: center;
    background-color: $csu-color-white;
    border-radius: 30px;
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 3.5rem;
    width: fit-content;

    .pagination-button-next,
    .pagination-button-prev {
      background-color: transparent;
      color: $csu-color-black;
      height: 30px;
      width: 30px;
    }

    .pagination-button-page {
      background-color: transparent;
      border: none;
      color: $csu-color-black;
      height: 30px;
      transition: none;
      width: 30px;

      &.active {
        background: $csu-color-blue;
        color: $csu-color-white;
      }
    }
  }
}

// Filter
.react-table__top {
  align-items: flex-end;
  background-color: $csu-color-white;
  border-radius: 30px 30px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  input,
  select {
    background-color: $csu-color-accent-light-blue;
    border: none;
    border-radius: 25px;
    color: $csu-color-grey;
    flex: 1;
    height: 40px;
    max-width: 200px;
    padding: 10px 20px;

    &:focus {
      background: $csu-color-accent-light-blue;
      border: none;
      border-radius: 30px;
    }
  }

  .filters {
    align-items: flex-end;
    display: flex;
  }

  .grouped-filter {
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    margin-inline-end: 1rem;
  }

  .grouped-filter-label {
    flex: 0 0 100%;
    margin-left: 10px;
  }
}
