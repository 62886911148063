.chat-attachment-viewer {
    .modal-body {
        padding: 0;
        position: relative;

        .chat-attachment-viewer-close-button {
            @include close-button-blue-cross-white-circle;
            position: absolute;
            top: 10px;
            left: 10px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .modal-content {
        border-radius: 0;
        border: 0;
    }
}

.chat-attachment-viewer-backdrop.show {
    background-color: $csu-color-black;
    z-index: 1050;
    opacity: 0.9;
}
