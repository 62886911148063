.g-zz-page.g-chat-overview {
    background-color: $csu-color-accent-light-blue;

    .g-chat-overview-container {
        .g-chat-overview-table {
            border-collapse: collapse;
            width: 100%;

            td,
            th {
                border: 1px solid $csu-border-color-light-gray;
                padding: 8px;
            }

            tr:nth-child(even) {
                background-color: $csu-color-white;
            }

            tr:hover {
                background-color: $csu-border-color-light-gray;
            }

            th {
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                background-color: $csu-color-blue;
                color: $csu-color-white;
            }
        }
    }

    .g-chat-overview-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
}

.g-chat-overview-loading {
    text-align: center;
}
