$g-basic-modal-header-height: 100px;
$g-basic-modal-footer-height: 75px;

.g-basic-modal {
    background-color: $csu-color-accent-light-blue;

    .g-basic-modal-content {
        background-color: $csu-color-accent-light-blue;
        border: 0;
    }

    .g-basic-modal-dialog {
        background-color: $csu-color-accent-light-blue;
    }

    .g-basic-modal-header {
        height: $g-basic-modal-header-height;
        border: 0;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        background-color: $csu-color-white;
        text-align: center;
        display: flex;
        position: relative;
        box-shadow: $csu-box-shadow-black;
        overflow: hidden;

        .g-basic-modal-header-image {
            @include fit-image-to-container;
        }

        .g-basic-modal-close-button {
            width: 35px;
            height: 35px;
            background-color: $csu-color-white;
            border-radius: 50%;
            position: absolute;
            bottom: 15px;
            left: 30px;
            cursor: pointer;
            padding-top: auto;
            display: flex;
            align-items: center;

            img {
                margin-left: auto;
                margin-right: auto;
                width: 20px;
                height: 20px;
            }
        }

        .modal-title {
            align-self: flex-end;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 20px;
            font-family: $csu-font-bold;
            font-size: 16px;
        }

        .close {
            position: absolute;
            bottom: 20px;
            left: 30px;
        }

        .g-basic-modal-header-image-container {
            height: 100%;
            width: 100%;
            border-bottom-right-radius: 58px;
            border-bottom-left-radius: 58px;
            overflow: hidden;
        }
    }

    .modal-footer {
        border: 0;
        height: $g-basic-modal-footer-height;
        background-color: $csu-color-white;
    }

    &.fullscreen-modal {
        .g-basic-modal-content {
            width: 100vw;
            min-height: 100%;
        }

        .modal-body {
            margin-top: $g-basic-modal-header-height + 30px;
            min-height: 100%;
            padding-bottom: $g-basic-modal-footer-height;
        }

        .g-basic-modal-header {
            top: 0px;
            width: 100%;
            z-index: 1;

            &.fixed {
                position: fixed;
            }
            // TODO 2131: Temporary until signalR is implemented for serviceDesk
            .g-refresh-button {
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 10px;
            }
        }

        .modal-footer {
            position: fixed;
            bottom: 0px;
            width: 100%;
        }

        .g-basic-modal-dialog {
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            margin: 0;
            padding: 0;
        }
    }
}
