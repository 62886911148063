.action-list-items {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr 20px;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba($csu-border-color-light-gray, 0.3);
    padding-left: 1rem;
    align-items: center;
    position: relative;

    p {
        margin: 0;
    }

    picture {
        width: 15px;
        height: 15px;
    }

    img {
        position: absolute;
        cursor: pointer;
    }
}

.action-list-title {
    text-transform: uppercase;
    font-family: $csu-font-bold;
}

.action-list-new-action {
    display: grid;
    grid-template-columns: 1fr 1fr 15px 15px;
    gap: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $csu-border-color-light-gray;
    padding-left: 1rem;
    align-items: baseline;

    & img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    & input {
        border: none;
    }
}

.action-list-new-item-dropdown {
    height: 25px;
    border-radius: .25rem;
    padding: 0;
    border: none;
}

.action-list-new-item-dropdown:hover {
    cursor: pointer;
}

.action-add-action-button-group {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    align-items: center;
    cursor: pointer;
}
.action-add-action-button-group > p {
    margin: 0;
    color: $csu-color-accent-grey-medium;
    font-style: italic;
}