h1 {
    font-family: $csu-font-black;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
    padding: 0;
    color: $csu-color-blue;
    font-weight: 900;
}

h2 {
    font-family: $csu-font-bold;
    font-weight: bold;
    color: $csu-color-blue;
    font-size: 18px;
}

h3 {
    font-family: $csu-font-default;
    font-weight: 500;
    color: $csu-color-blue;
    font-size: 10px;
}

h4 {
    font-family: $csu-font-default;
    font-weight: 500;
    color: $csu-color-grey;
    font-size: 12px;
}

div,
p {
    font-family: $csu-font-default;
    font-size: 12px;
}

.g-text-green {
    color: $csu-color-accent-green;
}
