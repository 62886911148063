.g-confirmation-prompt {
    .g-confirmation-prompt-buttons {
        width: 100%;
        .g-btn {
            width: calc(50% - 5px);

            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
}
