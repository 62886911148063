.avg-gdpr-management {
  h1 {
    margin-bottom: 20px;
  }

  .avg-gdpr-section-container {
    max-width: 500px;
    h2 {
      margin-bottom: 10px;
    }

    input {
      width: 400px;
      margin: 20px 0;
    }
  }
}

.delete-chats-confirmation-details-container {
  margin-block: 20px;
  
  .delete-chats-confirmation-details-row {
    display: flex;
    align-items: center;
    flex-direction: row;

    h3 {
        color: $csu-color-black;
        font-weight: bold;
        font-size: $csu-fontsize-normal;
        margin: 0;
        margin-right: 10px;
    }
  }
}

.invalid-guid-warning {
  padding: .5rem;
  color: $csu-color-red;
}
