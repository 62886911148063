.g-nav-bar {
  background-color: $csu-color-white;
  height: $g-navbar-height;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: $csu-box-shadow-black;
}

.g-nav {
  z-index: 1;
  width: 100%;
  margin: auto;
}
