body .g-btn {
  background-color: $csu-color-blue;
  border: none;
  border-radius: 30px;
  color: $csu-color-white;
  font-family: $csu-font-default;
  font-size: $csu-fontsize-normal;
  font-weight: 400;
  height: $csu-button-size-small-height;
  text-align: center;
  transition: 200ms all ease-in-out;
  width: $csu-button-size-normal-width;

  img {
    max-height: 20px;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.g-button-red {
    background-color: $csu-color-red;
    color: $csu-color-white;
  }

  &.g-button-red:hover:not(.disabled) {
    background-color: $csu-color-accent-red;
  }

  &.g-button-blue-outline {
    background-color: $csu-color-white;
    border: 1px solid $csu-color-blue;
    color: $csu-color-blue;
  }

  &.g-button-blue-outline:hover:not(.disabled) {
    background-color: $csu-color-blue;
    color: $csu-color-white;
  }

  &.g-button-blue {
    background-color: $csu-color-blue;
    color: $csu-color-white;

    .spinner-border {
      color: $csu-color-white;
    }
  }

  &.g-button-blue:hover:not(.disabled) {
    background-color: $csu-color-dark-blue;
  }

  &.g-button-pink-gradient {
    background: $csu-gradient-roze;
    color: $csu-color-white;
  }

  &.g-button-pink-gradient:hover:not(.disabled) {
    background: $csu-gradient-roze-hover;
  }

  &.g-dropdown-button {
    background-image: url("../../../images/arrow_forward.svg");
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    border: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    font-size: $csu-fontsize-large;
    font-weight: normal;
    height: 60px;
    padding-left: 20px;
    text-align: start;
    width: 100%;
  }

  // Spinner
  .spinner-border {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
  }
}
