$form-check-input-width: 1.33334em;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.25em;

@import "~bootstrap/scss/bootstrap";

// Forms
input.form-control {
  height: $input-height;
}

input:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

.row > * {
  padding-left: 0;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

// Hide number increase and decrease buttons on number input fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Hide number increase and decrease buttons on number input fields
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Modal
.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
  justify-content: center;
}

// Spinner
.spinner-border {
  color: $csu-color-blue;
}
